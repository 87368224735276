import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import Section from "../components/elements/Section"
// import Container from "../components/elements/Container"
import { default as BannerHome } from "../components/Home/Banner"

const NotFoundPage = ({ data }) => (
  <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
    <SEO title="404: Page introuvable" />
    {/* <Section>
      <Container>
        <h1>Page introuvable</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </Section> */}
    <BannerHome
      title="Page introuvable"
      baseline="Sélectionnez le coaching qui vous correspond"
      imageBanner={data.banner.childImageSharp.fluid}
      imageHeight="600px"
      imageMobileHeight="550px"
    />
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query {
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    banner: file(relativePath: { eq: "e-book.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
