import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Section from "../../elements/Section"
import Container from "../../elements/Container"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
    align-items: center;
  }
`
const Title = styled.p`
  color: ${props => props.theme.white};
  font-family: Rajdhani;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
  margin-top: 4rem;
  margin-bottom: 15px;
  line-height: 1;
  @media (min-width: ${globalVariables.minTablet}) {
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 44px;
  }
`

const Baseline = styled.h1`
  margin-bottom: 2rem;
  text-transform: inherit;
  color: ${props => props.theme.white};
  font-weight: 400;
  font-size: 28px;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 32px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${globalVariables.maxMobile}) {
    a {
      margin-right: 0;
      &:first-child {
        margin-right: 10px;
      }
      span.btn-text {
        font-size: 18px;
      }
    }
  }

  @media (min-width: ${globalVariables.minTablet}) {
    a {
      height: 38px;
    }
    span.btn-text {
      font-size: 20px;
    }
  }
`

const Banner = ({
  title,
  baseline,
  imageBanner,
  imageHeight,
  imageMobileHeight,
  isfirstBlock,
}) => {
  return (
    <Section
      padding="0"
      isfirstBlock={isfirstBlock}
      imageHeight={imageHeight}
      imageMobileHeight={imageMobileHeight}
    >
      <Img fluid={imageBanner} />
      <Wrapper>
        <Container>
          <Content>
            <Title>{title}</Title>
            <Baseline>{baseline}</Baseline>
            <ButtonGroup>
              <Button
                title="Pour homme"
                as="link"
                to="/coach-sportif-personnel-homme"
                colors="secondaryWithBg"
                margin="0 1rem 0.5rem 0"
              />
              <Button
                title="Pour femme"
                as="link"
                to="/coach-sportif-personnel-femme"
                colors="primaryWithBg"
                margin="0 1rem 0.5rem 0"
              />
            </ButtonGroup>
          </Content>
        </Container>
      </Wrapper>
    </Section>
  )
}

export default Banner
